// Layout.jsx
import { React, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import { useLoading } from './LoadingContext';
import Loader from './Loader';

function Layout() {

  const { isLoading, stopLoading } = useLoading();
  // call this when change selectedLanguage or url
  useEffect(() => {
    setTimeout(() => {
      stopLoading();
    }, 1200);
  }, [stopLoading]);

  return (
    <div>
      {isLoading && <Loader />}
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
