import React, { useState, useEffect } from "react";
import BraboLogoDark from '../assets/images/logo_dark.png';
import BraboLogoLight from '../assets/images/logo_light.png';
import { Link } from "react-router-dom";
import { BASE_URL } from "../api";
import { useLanguage } from './LanguageContext';
import { useTranslation } from 'react-i18next';
import FooterForm from "../hubspotforms/FooterForm";

function Footer() {
  const { t } = useTranslation();
  const [footerOne, setFooterOne] = useState([]);
  const [footerTwo, setFooterTwo] = useState([]);
  const [footerThree, setFooterThree] = useState([]);
  const [footerFour, setFooterFour] = useState([]);
  const [footerFive, setFooterFive] = useState([]);
  const [footerPrivacy, setFooterPrivacy] = useState([]);
  const [footerCopyright, setFooterCopyright] = useState([]);
  const [footerSocialMedia, setFooterSocialMedia] = useState([]);

  const { language: langCode } = useLanguage();

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response_footer1 = await fetch(`${BASE_URL}/footer_braboappmenu`);
        const footerOne = await response_footer1.json();
        setFooterOne(footerOne);

        // Footer2
        const response_footer2 = await fetch(`${BASE_URL}/footer_platformmenu`);
        const footerTwo = await response_footer2.json();
        setFooterTwo(footerTwo);

        // Footer3
        const response_footer3 = await fetch(`${BASE_URL}/footer_resourcemenu`);
        const footerThree = await response_footer3.json();
        setFooterThree(footerThree);

        // Footer4
        const response_footer4 = await fetch(`${BASE_URL}/footer_companymenu`);
        const footerFour = await response_footer4.json();
        setFooterFour(footerFour);

        // Footer5
        const response_footer5 = await fetch(`${BASE_URL}/footer_partnermenu`);
        const footerFive = await response_footer5.json();
        setFooterFive(footerFive);

        // PrivacyPolicy
        const response_privacy = await fetch(`${BASE_URL}/footer-privacy-policy`);
        const footerPrivacy = await response_privacy.json();
        setFooterPrivacy(footerPrivacy);

        // PrivacyPolicy
        const response_copyright = await fetch(`${BASE_URL}/widgets-copyright`);
        const footercopyright = await response_copyright.json();
        setFooterCopyright(footercopyright);

        // socialmedia
        const response_socialmidea = await fetch(`${BASE_URL}/social-media-links`);
        const footerSocialMedia = await response_socialmidea.json();
        setFooterSocialMedia(footerSocialMedia);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  // Function to decode HTML entities
  const decodeHtmlEntities = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };
  return (
    <div>
      {/* <!-- footer --> */}
      <footer>
        <div className="container">
          <div className="footer-logo mb-4 mobile-logo">
            <Link to={`/${langCode}`}>
              <img className="img-fluid logo-dark" src={BraboLogoDark} alt="Brabo logo" width="160" height="32" />
              <img className="img-fluid logo-light" src={BraboLogoLight} alt="Brabo logo" width="160" height="32" />
            </Link>
          </div>
          <div className="row flex-row-reverse justify-content-between">
            <div className="col-lg-8 offset-md-1">
              <div className="links">

                <ul>
                  <p className="mb-3">Use Cases</p>
                  {footerOne.map((footer_menu1, index) => (
                    <li key={index}>
                      <Link to={`/${langCode}${footer_menu1.url}`} >{decodeHtmlEntities(footer_menu1.title)}</Link>
                    </li>
                  ))}
                </ul>

                <ul>
                  <p className="mb-3">Platform</p>
                  {footerTwo.map((footer_menu2, index) => (
                    <li key={index}>
                      <Link to={`/${langCode}${footer_menu2.url}`} >{decodeHtmlEntities(footer_menu2.title)}</Link>
                    </li>
                  ))}
                </ul>

                <ul>
                  <p className="mb-3"><Link to={`/${langCode}/resources`}>Resources</Link></p>
                  {footerThree.map((footer_menu3, index) => (
                    <li key={index}>
                      <Link to={`/${langCode}${footer_menu3.url}`} >{decodeHtmlEntities(footer_menu3.title)}</Link>
                    </li>
                  ))}
                </ul>

                <ul>
                  <p className="mb-3">Company</p>
                  {footerFour.map((footer_menu4, index) => (
                    <li key={index}>
                      <Link to={`/${langCode}${footer_menu4.url}`} >{decodeHtmlEntities(footer_menu4.title)}</Link>
                    </li>
                  ))}
                </ul>

                <ul>
                  <p className="mb-3">Partners</p>
                  {footerFive.map((footer_menu5, index) => (
                    <li key={index}>
                      <Link to={`/${langCode}/become-partner`}>{decodeHtmlEntities(footer_menu5.title)}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 mt-4 mt-md-0">
              <div className="footer-logo mb-4 desktop-logo">
                <Link to={`/${langCode}`} className="mb-5" style={{ float: 'left' }}>
                  <img className="img-fluid logo-dark" src={BraboLogoDark} alt="Brabo logo" width="200" height="93" />
                  <img className="img-fluid logo-light" src={BraboLogoLight} alt="Brabo logo" width="200" height="93" />
                </Link>
                <Link to={`/company/get-in-touch/`} className="btn btn-orange">Get In Touch</Link>
              </div>
              {/* <FooterForm /> */}
            </div>
          </div>
          <div className="row footer-bottom">
            <div className="col-lg-6">
              <ul className="mb-4">
                {footerPrivacy.map((footer_privacy, index) => (
                  <li key={index}><Link to={`/${langCode}${footer_privacy.url}`}>{decodeHtmlEntities(footer_privacy.title)}</Link></li>
                ))}

                {/* <li><Link to={`/${langCode}/privacy-policy`}>{t('Privacy Policy')}</Link></li>
                <li><Link to={`/${langCode}/terms-condition`}>{t('Terms of Use')}</Link></li> */}
              </ul>
              {footerCopyright ? (
                <p>{footerCopyright.content}</p>
              ) : (
                <p>Loading</p>
              )
              }
            </div>
            <div className="col-lg-6">
              <ul className="social-media">
                {footerSocialMedia.map((socilamedia, index) => (
                  <li key={index}>
                    <Link to={socilamedia.link} target="_blank">
                      <img src={socilamedia.icon} alt={socilamedia.name} width={42} height={42} />
                    </Link>
                  </li>
                ))}

              </ul>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- footer --> */}
    </div>
  );
}

export default Footer;
