// LoadingContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const { pathname } = useLocation();

    const startLoading = () => {
        console.log('Starting loading');
        setIsLoading(true);
    };

    const stopLoading = () => {
        console.log('Stopping loading');
        setIsLoading(false);
    };

    // Use useEffect to start loading when the language path changes
    useEffect(() => {
        console.log('Language path changed. Starting loading.');
        startLoading();

    }, [pathname]); // Only re-run the effect if pathname changes

    return (
        <LoadingContext.Provider value={{ isLoading, startLoading, stopLoading }}>
            {children}
        </LoadingContext.Provider>
    );
};

export const useLoading = () => {
    return useContext(LoadingContext);
};
