// LanguageSwitcher.js
import React, { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useLanguage } from './LanguageContext';
import { BASE_URL } from '../api';
import { useTranslation } from 'react-i18next';


const LanguageSwitcher = () => {
    const { language, changeLanguage, languages, setLanguages } = useLanguage();
    const navigate = useNavigate();
    const { langCode } = useParams();
    const location = useLocation();
    const { i18n } = useTranslation();

    const handleLanguageChange = (newLanguage) => {
        changeLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
        const currentPath = location.pathname;
        const newPath = currentPath.startsWith(`/${langCode}`)
            ? currentPath.replace(`/${langCode}`, `/${newLanguage}`)
            : `/${newLanguage}${currentPath}`;

        navigate(newPath);
    };
    const fetchData = async () => {
        try {
            if (langCode) {
                changeLanguage(langCode);
                i18n.changeLanguage(langCode);
            }
            else {
                changeLanguage('en');
                i18n.changeLanguage('en');
            }

            const response = await fetch(`${BASE_URL}/languages`);
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const data = await response.json();
            //console.log('Languages data:', data);
            setLanguages(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData(); // Call the function immediately when the component mounts
    }, []); // Empty dependency array to run only once when mounted


    if (languages.length === 0) {
        //console.log('Invalid languages data:', languages.length);
        return <div>Loading...</div>;
    }
    else {
        //console.log('valid languages data:', languages);
        const languagesArray = Object.values(languages);
        return (
            <div>
                <select value={language} onChange={(e) => handleLanguageChange(e.target.value)}>
                    {languagesArray.map((lang) => (
                        <option key={lang.code} value={lang.code}>
                            {lang.native_name}
                        </option>
                    ))}
                </select>
            </div>
        );
    }


};

export default LanguageSwitcher;